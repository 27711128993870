import { render, staticRenderFns } from "./TextWithImage.vue?vue&type=template&id=36bef8af&scoped=true&"
import script from "./TextWithImage.vue?vue&type=script&lang=js&"
export * from "./TextWithImage.vue?vue&type=script&lang=js&"
import style0 from "./TextWithImage.vue?vue&type=style&index=0&id=36bef8af&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bef8af",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default})


export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasImage() {
      return this.data.image?.id
    },
  },
}
